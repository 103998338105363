<template>
  <SdPage
    id="listing-settings-page"
    :style="$viewport.gt.md && isRenderedFromUnitPage ? 'padding: 0;' : ''"
  >
    <div
      v-if="sections && sections.length"
      class="tabs-panel col-3"
    >
      <ElTabs
        :value="activeSectionName"
        tab-position="left"
        @tab-click="changeTab"
      >
        <ElTabPane
          v-for="section in sections"
          :key="section.label"
          :label="section.label"
          :name="section.name"
        />
      </ElTabs>
    </div>
    <div class="tabs-content col">
      <PropertyEdit
        :active-section-name="activeSectionName"
        :is-rendered-from-unit-page="isRenderedFromUnitPage"
        @expand-click="changeTab"
        @reloadUnit="$emit('reloadUnit')"
        @start-fetching="$emit('start-fetching')"
        @stop-fetching="$emit('stop-fetching')"
        @set-showing-profile-available="setShowingProfileAvailableConditions"
      />
    </div>
  </SdPage>
</template>

<script>
import { ref } from '@vue/composition-api';
import SdPage from '@/components/common/layout/SdPage';
import PropertyEdit from '@/pages/App/PropertyEdit';
import { editSectionsDefinitions } from '@/constants/Unit';

export default {
  name: 'UnitListingSettings',
  components: {
    SdPage,
    PropertyEdit,
  },
  props: {
    isRenderedFromUnitPage: {
      type: Boolean,
    },
    isLoadingInnerPage: {
      type: Boolean,
    },
  },

  setup() {
    const sections = ref();
    const activeSectionName = ref();
    return {
      setShowingProfileAvailableConditions,
      sections,
      activeSectionName,
      changeTab,
    };

    function setShowingProfileAvailableConditions({ isShowingProfileAvailable, property, showingProfile }) {
      if (isShowingProfileAvailable) {
        sections.value = editSectionsDefinitions.filter((section) => section.isVisible({ property, showingProfile }));
        const tabFromURL = getSectionNameFromURL();
        if (tabFromURL) {
          activeSectionName.value = getSectionNameFromURL();
        } else {
          changeTab({ name: editSectionsDefinitions[0].name });
        }
      } else {
        sections.value = [editSectionsDefinitions[0]];
        changeTab({ name: editSectionsDefinitions[0].name });
      }
    }

    function getSectionNameFromURL() {
      const initialParams = new URLSearchParams(window.location.search);
      if (initialParams.get('section')) {
        const selectedSection = sections.value.find((section) => section.name === initialParams.get('section'));
        if (selectedSection && selectedSection.name) {
          return selectedSection.name;
        }
      }
      return undefined;
    }

    function changeSectionParamInUrl(sectionName) {
      const params = new URLSearchParams(window.location.search);
      params.set('section', sectionName);
      window.history.replaceState({}, '', decodeURIComponent(`${window.location.pathname}?${params}`));
    }

    function changeTab(tabComponent) {
      const tabName = tabComponent.name;
      activeSectionName.value = tabName;
      changeSectionParamInUrl(tabName);
    }
  },
};
</script>
<style lang="scss">
@import "@/styles/utils";

#listing-settings-page {
  display: flex;

  @include generate-sidebar-tabs-vertical-design;
  @include media-breakpoint-down(lg) {
    .tabs-panel {
      display: none;
    }

    .el-tabs__header {
      display: none;
    }
  }
}
</style>
